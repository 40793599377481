@import url("https://fonts.googleapis.com/css2?family=Caveat");

:root {
  --font-size-small: 2rem;
  --font-size-large: 5rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url(../assets/bg2.gif);
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
