.Box {
  position: relative;
  color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: column;
}

.text {
  transform: translate(-50%, -50%) translateX(var(--translateX)) translateY(var(--translateY));
  position: absolute;
  left: 50%;
  top: 50%;
}